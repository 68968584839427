/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Importuj tylko główny plik SCSS
import './src/styles/global.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

export const onClientEntry = () => {
  window.onload = () => {
    console.log("Strona załadowana, inicjalizacja Bootstrap")
  }

  // Preload krytycznych zasobów
  const preloadLink = document.createElement('link');
  preloadLink.rel = 'preload';
  preloadLink.as = 'style';
  preloadLink.href = '/styles.css'; // Ścieżka do głównego pliku CSS
  document.head.appendChild(preloadLink);
}

export const onRouteUpdate = ({ location }) => {
  console.log("Zmiana strony na:", location.pathname)
}
